<template>
  <v-card>
    <v-card-text>
      Huom!
      <br/>
      0. Be careful when adding groups here, these are added to every team in this sport<br/>
      1. Mirroring does not work <br/>
      2. Changing group name does not work <br/>
      3. Adding tags to groups don't add them to existing teams, only the ones created later <br/>
    </v-card-text>
    <tags :sport="true"></tags>
  </v-card>
</template>

<script>
import Tags from '@/views/Tags.vue'
export default {
  components: {
    Tags
  }
}
</script>